import en from './en.json'
import es from './es.json'

export const translations = [
  { type: 'en', en },
  { type: 'es', es },
]

export const resources = {
  en,
  es,
}
