import { watchCheckVerify, watchStartVerify } from './store/verifySlice'

const verifySagas = [watchStartVerify, watchCheckVerify]

import verifyReducer, { name as verifyName } from './store/verifySlice'

export { verifyReducer, verifySagas, verifyName }
export { watchCheckVerify, watchStartVerify }
export { actionDefaults, labelDefaults, translations } from './config'
export { VerifyInputPage } from './components/VerifyInput'
export { VerifyMethodPage } from './components/VerifyMethod'
export { Verify } from './components/Verify'
