import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  FormControl,
  Box,
  OutlinedInput,
  FormHelperText,
  Alert,
  AlertTitle,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  SentTo,
  SecondsToMinutes,
  useTimeLeft,
  Loading,
  EnterVerification,
} from './utils'

import {
  InputButtons,
  StyledButton,
  StyledInputContainer,
  StyledResendCode,
  StyledResent,
} from './styled'

const COUNTDOWN_TIME = 10 * 60 - 1 // 10 minutes countdown
const RESEND_SECONDS = 30 // show resend option 30 seconds after
const INITIAL_TIME_UNTIL_RESEND = COUNTDOWN_TIME - RESEND_SECONDS

export function PureVerifyInputPage({
  loading,
  obfuscate,
  selectedCustomer,
  checking,
  correctCode,
  error,
  setGoBack,
  sendCode,
  timeLeft,
  showResend,
  showSuccess,
  showResent,
  codeInput,
  setCodeInput,
  onResendClick,
}) {
  const { t } = useTranslation()

  if (loading) {
    return (
      <StyledInputContainer>
        <Loading />
      </StyledInputContainer>
    )
  }

  if (
    !selectedCustomer ||
    !selectedCustomer.name ||
    selectedCustomer.name.length <= 0
  ) {
    return (
      <StyledInputContainer>
        <Loading />
      </StyledInputContainer>
    )
  }

  return (
    <section>
      <section>
        {!showSuccess && (
          <div>
            <Typography variant="h6">
              <EnterVerification
                obfuscate={obfuscate}
                customer={selectedCustomer}
              />
            </Typography>
            <Typography variant="subtitle1" paddingBottom={'20px'}>
              {t('pages.verify.codeWillExpire')} {SecondsToMinutes(timeLeft)}
            </Typography>
            <StyledInputContainer>
              <Box component="form" noValidate autoComplete="off">
                <FormControl sx={{ width: '25ch' }}>
                  <OutlinedInput
                    placeholder="Enter code"
                    value={codeInput ?? ''}
                    inputProps={{
                      maxLength: 6,
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    error={!checking && !correctCode && error}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setCodeInput(e.target.value)
                      } else {
                        setCodeInput(null)
                      }
                    }}
                  />
                  {!checking && !correctCode && error && (
                    <FormHelperText error>
                      {t('pages.verify.wrongCode')}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              {showResend && !showResent && (
                <StyledResendCode
                  to={selectedCustomer?.email}
                  onClick={() => onResendClick()}
                >
                  Resend code
                </StyledResendCode>
              )}
              {showResent && !showResend && (
                <StyledResent>Resent!</StyledResent>
              )}
            </StyledInputContainer>
            <InputButtons>
              <StyledButton onClick={() => setGoBack()}>Cancel</StyledButton>
              <StyledButton
                disabled={!codeInput}
                style={{ backgroundColor: !codeInput && 'grey' }}
                onClick={() => sendCode(codeInput, SentTo(selectedCustomer))}
              >
                Verify
              </StyledButton>
            </InputButtons>
          </div>
        )}
      </section>
      {!checking && correctCode && showSuccess && (
        <div>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Your code has been successfully verified!
          </Alert>
          <Box sx={{ mt: '15px' }}>
            <Button
              variant="contained"
              color="success"
              disableElevation
              onClick={() => setGoBack()}
            >
              Return to component
            </Button>
          </Box>
        </div>
      )}
      {checking && <Loading />}
    </section>
  )
}

PureVerifyInputPage.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  selectedCustomer: PropTypes.any,
  obfuscate: PropTypes.bool,
  checking: PropTypes.bool,
  correctCode: PropTypes.bool,
  setGoBack: PropTypes.func,
  sendCode: PropTypes.func,
  timeLeft: PropTypes.number,
  showResend: PropTypes.bool,
  showSuccess: PropTypes.bool,
  showResent: PropTypes.bool,
  codeInput: PropTypes.string,
  setCodeInput: PropTypes.func,
  onResendClick: PropTypes.func,
}

export function VerifyInputPage({
  loading,
  error,
  resend,
  selectedCustomer,
  obfuscate,
  checking,
  correctCode,
  sendCode,
  setGoBack,
  onResendButton,
}) {
  const [showResend, setResend] = useState(resend)
  const [showSuccess, setShowSuccess] = useState(false)
  const [timeToResend, setTimeToResend] = useState(INITIAL_TIME_UNTIL_RESEND)
  const [showResent, setShowResent] = useState(false)
  const [codeInput, setCodeInput] = useState(null)

  const timeLeft = useTimeLeft(COUNTDOWN_TIME)

  useEffect(() => {
    if (timeLeft === timeToResend) {
      setResend(true)
    }

    if (timeLeft === timeToResend - 5) {
      setShowResent(false)
    }

    if (correctCode) {
      setShowSuccess(true)
    }
  }, [timeLeft])

  const onResendClick = () => {
    setTimeToResend(COUNTDOWN_TIME - RESEND_SECONDS)
    setResend(false)
    setShowResent(true)
    onResendButton(SentTo(selectedCustomer), selectedCustomer.method)
  }

  return (
    <PureVerifyInputPage
      loading={loading}
      error={error}
      selectedCustomer={selectedCustomer}
      obfuscate={obfuscate}
      checking={checking}
      correctCode={correctCode}
      sendCode={sendCode}
      setGoBack={setGoBack}
      timeLeft={timeLeft}
      showResend={showResend}
      showSuccess={showSuccess}
      timeToResend={timeToResend}
      showResent={showResent}
      codeInput={codeInput}
      setCodeInput={setCodeInput}
      onResendClick={onResendClick}
    />
  )
}
