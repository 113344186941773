import { createSlice } from '@reduxjs/toolkit'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  startVerifyService,
  checkVerifyService,
} from '../services/verifyService'

export const name = 'verify'
const initialState = {
  loading: false,
  resend: false,
  obfuscate: false,
  sending: false,
  error: null,
  customers: [],
  startedVerify: null,
  checking: null,
  correctCode: null,
}

const verifySlice = createSlice({
  name,
  initialState,
  reducers: {
    resetToInitial(state) {
      state.startedVerify = null
      state.correctCode = false
    },
    startVerify(state) {
      state.loading = true
    },
    startVerifyStart(state) {
      state.loading = true
      state.error = null
      state.startedVerify = true
    },
    startVerifySuccess(state) {
      state.loading = false
    },
    startVerifyFail(state, action) {
      state.error = action.payload?.error
    },
    checkVerify(state) {
      state.loading = true
    },
    checkVerifyStart(state) {
      state.loading = true
      state.error = null
      state.checking = true
    },
    checkVerifySuccess(state) {
      state.loading = false
      state.checking = false
      state.correctCode = true
    },
    checkVerifyFail(state, action) {
      state.loading = false
      state.checking = false
      state.correctCode = false
      state.error = action.payload?.error
    },
  },
})

export const {
  resetToInitial,
  startVerify,
  startVerifyStart,
  startVerifySuccess,
  startVerifyFail,
  checkVerify,
  checkVerifyStart,
  checkVerifySuccess,
  checkVerifyFail,
} = verifySlice.actions
export default verifySlice.reducer

export function* startVerifySaga(action) {
  if (
    !action.payload?.channel ||
    !action.payload?.to ||
    !action.payload?.apiBaseUrl ||
    !action.payload?.accessToken
  ) {
    console.warn('invalid start verify payload', action)
    return yield put(
      startVerifyFail({ error: 'Missing parameters to startVerify' }),
    )
  }
  console.log('saga startVerify', action)
  const { channel, to, apiBaseUrl, accessToken } = action.payload
  const body = {
    Token: accessToken,
    channel,
    to,
  }
  yield put(startVerifyStart())
  try {
    const result = yield call(startVerifyService, apiBaseUrl, body)
    console.log('started verify', result)
    yield put(startVerifySuccess({ ...result }))
  } catch (error) {
    yield put(startVerifyFail({ error }))
  }
}

export function* watchStartVerify() {
  yield takeEvery(startVerify.type, startVerifySaga)
}

export function* checkVerifySaga(action) {
  if (
    !action.payload?.verification_code ||
    !action.payload?.to ||
    !action.payload?.apiBaseUrl ||
    !action.payload?.accessToken
  ) {
    console.warn('invalid start verify payload', action)
    return yield put(
      checkVerifyFail({ error: 'Missing parameters to checkVerify' }),
    )
  }
  console.log('saga checkVerify', action)
  const { verification_code, to, apiBaseUrl, accessToken } = action.payload
  const body = {
    Token: accessToken,
    verification_code,
    to,
  }
  yield put(checkVerifyStart())
  try {
    const result = yield call(checkVerifyService, apiBaseUrl, body)
    console.log('starting check verify', result)
    yield put(checkVerifySuccess({ ...result }))
  } catch (error) {
    console.log('error check verify', error)
    yield put(checkVerifyFail({ error }))
  }
}

export function* watchCheckVerify() {
  yield takeEvery(checkVerify.type, checkVerifySaga)
}
