import { Container, Paper, styled, Button, Typography } from '@mui/material'

export const StyledContainer = styled(Container)(({ theme }) => {
  return {
    [theme.breakpoints.down('lg')]: {
      paddingTop: 10,
      minHeight: 'inherit',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 12,
    },
  }
})

export const MethodButtons = styled(Paper)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
  boxShadow: 'none',
}))

export const InputButtons = styled(Paper)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'left',
  gap: '10px',
  boxShadow: 'none',
  marginTop: '15px',
}))

export const StyledButton = styled(Button)({
  width: '100px',
})

export const StyledInputContainer = styled(Container)(({ theme }) => {
  return {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
  }
})

export const StyledResent = styled(Typography)(() => ({
  fontWeight: 'bold',
  textDecoration: 'none',
  padding: '10px',
  display: 'inline-block',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '200%',
  color: 'green',
  cursor: 'default',
}))

export const StyledResendCode = styled(Typography)(() => ({
  fontWeight: 'bold',
  textDecoration: 'none',
  padding: '10px',
  display: 'inline-block',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '200%',
  cursor: 'pointer',
  color: '#2a58b1',
}))

export const ButtonGroup = styled(Paper)(() => ({
  paddingTop: '20px',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '10px',
  boxShadow: 'none',
}))
