import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ConfirmationModal,
  CustomerSelectionForm,
  Loading,
  SelectMethod,
} from './utils'

import { StyledContainer } from './styled'

export function PureVerifyMethodPage({
  loading,
  customer,
  obfuscate,
  onConfirm,
  open,
  handlePhoneSelect,
  handleClose,
  handleClickOpen,
  selectCustomer,
  chosenMethod,
  newCustomers,
  selectedCustomer,
  setSelectedUser,
  selectedPhone,
}) {
  const { t } = useTranslation()

  if (loading || !(customer && customer.name && customer.name.length > 0)) {
    return (
      <StyledContainer>
        <Loading />
      </StyledContainer>
    )
  }

  return (
    <section>
      {loading && <Loading />}
      {newCustomers && newCustomers.length > 0 && (
        <div>
          <Typography variant="h6" paddingBottom={'10px'} align={'center'}>
            {t('pages.verify.header')}
          </Typography>
          <Typography
            variant="subtitle1"
            paddingBottom={'10px'}
            align={'center'}
          >
            {t('pages.verify.selectTitle')}
          </Typography>
          <CustomerSelectionForm
            newCustomers={newCustomers}
            setSelectedUser={setSelectedUser}
            selectCustomer={selectCustomer}
            selectedCustomer={selectedCustomer}
            selectedPhone={selectedPhone}
            handlePhoneSelect={handlePhoneSelect}
            obfuscate={obfuscate}
          />
          <br></br>
          {selectedCustomer && selectedPhone && (
            <SelectMethod handleClickOpen={handleClickOpen} />
          )}
        </div>
      )}
      {selectedCustomer && (
        <ConfirmationModal
          handleClose={handleClose}
          chosenMethod={chosenMethod}
          selectedCustomer={selectedCustomer}
          selectedPhone={selectedPhone}
          obfuscate={obfuscate}
          onConfirm={onConfirm}
          open={open}
        />
      )}
    </section>
  )
}

PureVerifyMethodPage.propTypes = {
  loading: PropTypes.bool,
  customer: PropTypes.any,
  obfuscate: PropTypes.bool,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  handlePhoneSelect: PropTypes.func,
  handleClose: PropTypes.func,
  handleClickOpen: PropTypes.func,
  selectCustomer: PropTypes.func,
  chosenMethod: PropTypes.string,
  newCustomers: PropTypes.any,
  selectedCustomer: PropTypes.any,
  setSelectedUser: PropTypes.func,
  selectedPhone: PropTypes.string,
}

export function VerifyMethodPage({
  customer,
  customers,
  loading,
  error,
  sending,
  obfuscate,
  getSelectedCustomer,
  accessToken,
  siteConfig,
  onConfirm,
}) {
  const [newCustomers, setNewCustomers] = useState(new Array())
  const [selectedCustomer, setSelectedUser] = useState(null)
  const [selectedPhone, setSelectedPhone] = useState('')
  const [open, setOpen] = useState(false)
  const [chosenMethod, setMethod] = useState('')

  useEffect(() => {
    if (customer) createCustomers()
  }, [selectedCustomer])

  const createCustomers = () => {
    let newCustomer = {
      name: customer.name,
      email: customer.primaryEmail,
      phoneNumbers: customer.customerIds
        ? customer.customerIds
            .filter((x) => x.recordType === 'phone')
            .map((x) => x.id)
        : [],
      companyName: '',
      selected: false,
    }

    const mappedCustomers = customers.map(
      ({ name, email, phoneNumber, companyName, selected }) => {
        return {
          name: name,
          email: email,
          phoneNumbers: new Array(phoneNumber),
          companyName: companyName,
          selected: selected,
        }
      },
    )

    setNewCustomers([newCustomer].concat(mappedCustomers))
  }

  const selectCustomer = (customer) => {
    if (customer) {
      const customerIndex = newCustomers.findIndex(
        (x) => x.email === customer.email,
      )

      const newData = []
      newCustomers.forEach((val) => newData.push(Object.assign({}, val)))

      newData.forEach((y) => (y.selected = false))

      newData[customerIndex].selected = true
      setSelectedUser(newData[customerIndex])

      if (selectedCustomer && selectedCustomer.email === customer.email) {
        newData[customerIndex].selected = false
        setSelectedUser('')
      }
      setNewCustomers(newData)
    }
  }

  const handleClickOpen = (method) => {
    const newSelected = {
      ...selectedCustomer,
      phoneNumber: selectedPhone,
      method: method,
    }

    delete newSelected.phoneNumbers
    delete newSelected.selected

    setOpen(true)
    setMethod(method)
    setSelectedUser(newSelected)
    getSelectedCustomer(newSelected)
    console.log(newSelected)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedUser(
      newCustomers.find((x) => x.email === selectedCustomer.email),
    )
  }

  const handlePhoneSelect = (event) => {
    setSelectedPhone(event.target.value)
  }

  return (
    <PureVerifyMethodPage
      obfuscate={obfuscate}
      customer={customer}
      customers={customers}
      loading={loading}
      error={error}
      sending={sending}
      getSelectedCustomer={getSelectedCustomer}
      accessToken={accessToken}
      siteConfig={siteConfig}
      onConfirm={onConfirm}
      open={open}
      chosenMethod={chosenMethod}
      newCustomers={newCustomers}
      selectedCustomer={selectedCustomer}
      setSelectedUser={setSelectedUser}
      handlePhoneSelect={handlePhoneSelect}
      handleClose={handleClose}
      handleClickOpen={handleClickOpen}
      selectCustomer={selectCustomer}
      selectedPhone={selectedPhone}
    />
  )
}
