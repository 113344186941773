import React, { useEffect, useState } from 'react'

import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  MenuItem,
  Autocomplete,
  TextField,
  Box,
  CircularProgress,
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import { StyledButton, MethodButtons } from './styled'

export function SentTo(selectedCustomer) {
  if (selectedCustomer.method === 'email') {
    return selectedCustomer.email
  }
  return selectedCustomer.phoneNumber
}

export function SecondsToMinutes(countdown) {
  const minutes = Math.floor(countdown / 60)
  const seconds = countdown - minutes * 60
  return minutes + ':' + (seconds < 10 ? '0' + seconds : seconds)
}

export function ObfuscatePhone(phone) {
  return phone.replace(phone.substring(4, 8), 'XXXX')
}

export function GetRecipient(chosenMethod, selectedCustomer, selectedPhone) {
  if (chosenMethod === 'email') {
    return selectedCustomer.email
  }

  return selectedPhone
}

export function CustomerSelectionForm({
  newCustomers,
  setSelectedUser,
  selectCustomer,
  selectedCustomer,
  selectedPhone,
  handlePhoneSelect,
  obfuscate,
}) {
  return (
    <Grid container spacing={2} paddingTop={'20px'} paddingBottom={'20px'}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        item
        spacing={3}
      >
        <Grid item xs={12} md={6} lg={6}>
          <Autocomplete
            id="combo-box-demo"
            options={newCustomers}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Email" />
            )}
            key={null}
            onChange={(event, selected, reason) => {
              if (reason === 'clear') {
                setSelectedUser(null)
                return
              } else {
                if (selected !== null) selectCustomer(selected)
              }
            }}
            getOptionLabel={(option) => option.email || ''}
            value={selectedCustomer}
            isOptionEqualToValue={(option, value) => {
              value.email === option.email
            }}
            disablePortal
            clearOnBlur
            handleHomeEndKeys
            selectOnFocus
          />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        item
        spacing={3}
      >
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            disabled
            fullWidth
            id="standard-disabled"
            label="Name"
            value={
              selectedCustomer && selectedCustomer.name.length > 0
                ? selectedCustomer.name
                : ''
            }
            variant="standard"
          />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        item
        spacing={3}
      >
        <Grid item xs={12} md={6} lg={6}>
          {selectedCustomer &&
            selectedCustomer.companyName &&
            selectedCustomer.companyName.length > 0 && (
              <TextField
                disabled
                fullWidth
                id="standard-disabled"
                label="Company"
                value={
                  selectedCustomer && selectedCustomer.companyName.length > 0
                    ? selectedCustomer.companyName
                    : ''
                }
                variant="standard"
              />
            )}
          {selectedCustomer && !selectedCustomer.companyName && (
            <TextField
              disabled
              fullWidth
              id="standard-disabled"
              label="Company"
              value="N/A"
              variant="standard"
            />
          )}
          {!selectedCustomer && (
            <TextField
              disabled
              fullWidth
              id="standard-disabled"
              label="Company"
              value=""
              variant="standard"
            />
          )}
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        item
        spacing={3}
      >
        <Grid item xs={12} md={6} lg={6}>
          {selectedCustomer &&
          selectedCustomer.phoneNumbers &&
          selectedCustomer.phoneNumbers.length > 0 ? (
            <Box component="form" noValidate autoComplete="off">
              <div>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Phone Number"
                  value={selectedPhone}
                  fullWidth
                  onChange={handlePhoneSelect}
                  variant="standard"
                >
                  {selectedCustomer.phoneNumbers.map((phone, index) => (
                    <MenuItem key={index} value={phone}>
                      {obfuscate ? ObfuscatePhone(phone) : <div>{phone}</div>}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          ) : (
            <Box component="form" noValidate autoComplete="off">
              <TextField
                disabled
                fullWidth
                id="standard-disabled"
                label="Phone Number"
                value={
                  selectedPhone
                    ? obfuscate
                      ? ObfuscatePhone(selectedPhone)
                      : selectedPhone
                    : ''
                }
                variant="standard"
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export function SelectMethod({ handleClickOpen }) {
  const { t } = useTranslation()
  return (
    <div>
      <Typography variant="h6" paddingBottom={'10px'} align={'center'}>
        {t('pages.verify.selectMethod')}
      </Typography>
      <MethodButtons>
        <StyledButton onClick={() => handleClickOpen('email')}>
          Email
        </StyledButton>
        <StyledButton onClick={() => handleClickOpen('sms')}>SMS</StyledButton>
        <StyledButton onClick={() => handleClickOpen('call')}>
          Voice
        </StyledButton>
        <StyledButton onClick={() => handleClickOpen('whatsapp')}>
          WhatsApp
        </StyledButton>
      </MethodButtons>
    </div>
  )
}

export function ConfirmationModal({
  handleClose,
  chosenMethod,
  selectedCustomer,
  selectedPhone,
  obfuscate,
  onConfirm,
  open = false,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ConfirmSend
        method={chosenMethod}
        sendTo={GetRecipient(chosenMethod, selectedCustomer, selectedPhone)}
        obfuscate={obfuscate}
      />
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
        <Button
          onClick={() =>
            onConfirm(
              chosenMethod,
              GetRecipient(chosenMethod, selectedCustomer, selectedPhone),
            )
          }
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function ConfirmSend({ method, sendTo, obfuscate }) {
  const { t } = useTranslation()

  const confirmVerification = t('pages.verify.confirmVerification')

  let phone, text
  if (obfuscate) {
    phone = ObfuscatePhone(sendTo)
  } else {
    phone = sendTo
  }

  switch (method) {
    case 'sms':
      text = confirmVerification + phone + t('pages.verify.viaSMS')
      break
    case 'email':
      text = confirmVerification + sendTo + '?'
      break
    case 'call':
      text = confirmVerification + phone + t('pages.verify.viaVoice')
      break
    case 'whatsapp':
      text = confirmVerification + phone + t('pages.verify.viaWhatsApp')
      break
    default:
  }
  return (
    <div>
      <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
    </div>
  )
}

export function useTimeLeft(seconds) {
  const [timeLeft, setTimeLeft] = useState(seconds)

  useEffect(() => {
    if (!timeLeft) return

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  return timeLeft
}

export function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  )
}

export function EnterVerification({ obfuscate, customer }) {
  const { t } = useTranslation()
  const verificationText = <span>{t('pages.verify.enterVerification')}</span>

  if (customer.method === 'email') {
    return (
      <section>
        {verificationText}
        {customer.email}
      </section>
    )
  }

  const obfuscatePhone = (phone, method) => {
    if (method === 'sms') {
      return phone.replace(phone.substring(4, 8), 'XXXX')
    }
    return phone
  }

  return (
    <section>
      {verificationText}
      {obfuscate ? (
        obfuscatePhone(customer.phoneNumber, customer.method)
      ) : (
        <span>{customer.phoneNumber}</span>
      )}
    </section>
  )
}
