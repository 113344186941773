(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@reduxjs/toolkit"), require("react"), require("@mui/material"), require("react-i18next"), require("react-redux"));
	else if(typeof define === 'function' && define.amd)
		define(["@reduxjs/toolkit", "react", "@mui/material", "react-i18next", "react-redux"], factory);
	else if(typeof exports === 'object')
		exports["@demoeng/usecase-verify"] = factory(require("@reduxjs/toolkit"), require("react"), require("@mui/material"), require("react-i18next"), require("react-redux"));
	else
		root["@demoeng/usecase-verify"] = factory(root["@reduxjs/toolkit"], root["react"], root["@mui/material"], root["react-i18next"], root["react-redux"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__106__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__901__, __WEBPACK_EXTERNAL_MODULE__238__, __WEBPACK_EXTERNAL_MODULE__756__) => {
return 