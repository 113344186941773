import axios from 'axios'

export function startVerifyService(apiBaseUrl, body) {
  return axios
    .post(apiBaseUrl + 'start-verify', body)
    .then((response) => response.data)
}

export function checkVerifyService(apiBaseUrl, body) {
  return axios
    .post(apiBaseUrl + 'check-verify', body)
    .then((response) => response.data)
}
