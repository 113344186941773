import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { VerifyMethodPage } from './VerifyMethod'
import { VerifyInputPage } from './VerifyInput'
import { useDispatch } from 'react-redux'

import { startVerify, checkVerify, resetToInitial } from '../store/verifySlice'

import { Container, Paper, styled, CircularProgress } from '@mui/material'

const StyledContainer = styled(Container)(({ theme }) => {
  return {
    [theme.breakpoints.down('lg')]: {
      paddingTop: 120,
      minHeight: 'inherit',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 100,
    },
  }
})

const StyledBody = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(3),
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
}))

export function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  )
}

export function VerifyPage({
  loading,
  resend,
  obfuscate,
  sending,
  error,
  customer,
  customers,
  accessToken,
  siteConfig,
  startedVerify,
  checking,
  correctCode,
  sendCode,
  setGoBack,
  onResendButton,
  onConfirm,
  selectedCustomer,
  getSelectedCustomer,
}) {
  if (loading || !(customer && customer.name && customer.name.length > 0)) {
    return (
      <StyledContainer>
        <Loading />
      </StyledContainer>
    )
  }

  return (
    <StyledContainer>
      <section>
        {!loading && !startedVerify && (
          <StyledBody>
            <VerifyMethodPage
              loading={loading}
              sending={sending}
              error={error}
              obfuscate={obfuscate}
              customer={customer}
              customers={customers}
              getSelectedCustomer={getSelectedCustomer}
              accessToken={accessToken}
              siteConfig={siteConfig}
              onConfirm={onConfirm}
            />
          </StyledBody>
        )}
        {!loading && startedVerify && (
          <StyledBody>
            <VerifyInputPage
              loading={loading}
              error={error}
              resend={resend}
              obfuscate={obfuscate}
              selectedCustomer={selectedCustomer}
              checking={checking}
              correctCode={correctCode}
              sendCode={sendCode}
              setGoBack={setGoBack}
              onResendButton={onResendButton}
            />
          </StyledBody>
        )}
      </section>
    </StyledContainer>
  )
}

VerifyPage.propTypes = {
  loading: PropTypes.bool,
}

export function Verify() {
  const {
    loading,
    error,
    resend,
    obfuscate,
    startedVerify,
    checking,
    correctCode,
  } = useSelector((state) => state.verify)
  const { customer } = useSelector((state) => state.customer)
  const customers = useSelector((state) => state.customerOptIn.customers)
  const accessToken = useSelector((state) => state.auth.accessToken)
  const siteConfig = useSelector((state) => state.siteConfig)

  const dispatch = useDispatch()

  const sendCode = (codeInput, to) => {
    const body = {
      accessToken,
      to: to,
      verification_code: codeInput,
      apiBaseUrl: siteConfig?.verifyApi,
    }
    dispatch(checkVerify(body))
  }

  const onResendButton = (to, method) => {
    const body = {
      accessToken,
      to: to,
      channel: method,
      apiBaseUrl: siteConfig?.verifyApi,
    }
    dispatch(startVerify(body))
  }

  const setGoBack = () => {
    dispatch(resetToInitial())
  }

  const onConfirm = (chosenMethod, to) => {
    const body = {
      accessToken,
      to: to,
      channel: chosenMethod,
      apiBaseUrl: siteConfig?.verifyApi,
    }
    dispatch(startVerify(body))
  }

  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const getSelectedCustomer = (customer) => {
    setSelectedCustomer(customer)
  }

  return (
    <VerifyPage
      loading={loading}
      error={error}
      resend={resend}
      obfuscate={obfuscate}
      customer={customer}
      customers={customers}
      accessToken={accessToken}
      siteConfig={siteConfig}
      startedVerify={startedVerify}
      checking={checking}
      correctCode={correctCode}
      sendCode={sendCode}
      setGoBack={setGoBack}
      onResendButton={onResendButton}
      onConfirm={onConfirm}
      selectedCustomer={selectedCustomer}
      getSelectedCustomer={getSelectedCustomer}
    />
  )
}
